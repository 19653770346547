import React from 'react';
import './settings.css'
class SettingsArea extends React.Component{
    constructor(props){
        super(props);
    }



    render(){

        return this.props.component
    }

}

export default SettingsArea;