import React from 'react'
import './securityInformation.css'
const securityInformationData=require('./securityInformation.json');
class SecurityInformation extends React.Component{

    constructor(props){
        super(props);
        this.state={

        }

    }



    render(){
        let info=securityInformationData.map((data)=>{
            return (
                <div className="securityInformation">
                    <h5>{data.title}</h5>
                    <p>{data.content}</p>
                </div>
            )
        })
        return(
            <div>
                {info}

                <button className="securityButton" onClick={this.props.goBack}>
                    Voltar
                </button>
            </div>
        )
    }

}

export default SecurityInformation;