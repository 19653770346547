import React from 'react'

class ContactUs extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        return (
            <div>
                This is the contactUs
            </div>
        )
    }

}

export default ContactUs;