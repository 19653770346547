import React from 'react';
import './settings.css'
class ChangeAddress extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selected:""
        }
    }



    render(){
        return (
            <div  className="settings-area">


            </div>
        )
    }

}

export default ChangeAddress;