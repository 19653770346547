import React from 'react';
import './App.css';
import TopNavigation from "./topnavigation/TopNavigation";
import HomePage from "./home_page/HomePage";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Cotacoes from "./quotation/Cotacoes";
import Registration from "./registration/Registration";
import Login from "./login/Login";
import {GoGear } from "react-icons/go";
import {  IoIosHome } from "react-icons/io";
import { GoSignOut, GoSignIn } from "react-icons/go";
import { FiShoppingCart } from "react-icons/fi";
import { FiUserPlus } from "react-icons/fi";
import AuthenticatedSuccessWindow from "./authenticated_site/AuthenticatedSuccessWindow";
import Settings from "./settings/Settings";
const languagePackage=require('./language/languages.json')
const plane = require('./resources/airplane1.jpg')
let initialNavigationOptions=[
    {value:"homePage",label: <IoIosHome/>,description:"home"},
    {value:"registration",label:<FiUserPlus/>,"description":"cadastrar"},
    {value:"login",label:<GoSignIn/>,"description": "entrar"}
]

let authenticatedNavigationOptions=[
    {value:"homePage",label: <IoIosHome/>,description:"home"},
    {value:"quotation",label:<FiShoppingCart/>,description: 'cotações'},
    {value:"logout",label:<GoSignOut/>,description:'sair'},
    {value:"settings","label":<GoGear/>,description: 'ajustes'},


]

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      content:<HomePage textContent={languagePackage.portuguese.welcomePage}/>,
        contentString:"homePage",
        siteToken:null,
        navigationOptions:"initialNavigationOptions",
        endpoint:null,
        loggedOut:null,
        options:initialNavigationOptions,
        language:"portuguese",
        languagePackage:languagePackage.portuguese
    }
    this.navigationEvent=this.navigationEvent.bind(this);
    this.authenticate=this.authenticate.bind(this);
    this.logout=this.logout.bind(this)
  }

  authenticate(authenticationData){
      let token=authenticationData.sessionToken;
      let url=authenticationData.url
      if(token&&url){
          localStorage.setItem("siteToken",token);
          localStorage.setItem('endpoint',url);
          this.setState(
              {
                  loggedOut:false,
                  siteToken:token,
                  endpoint:url,
                  content:<AuthenticatedSuccessWindow/>,
                  contentString:"authenticatedSuccessWindow",
                  options:authenticatedNavigationOptions
              }
              )
      }
  }

  logout(){
      localStorage.removeItem("siteToken");
      localStorage.removeItem("endpoint");
      let language=this.state.language
      this.setState(
          {
              siteToken:null,
              endpoint:null,
              loggedOut:true,
              options:initialNavigationOptions,
              content:<HomePage
                  textContent={this.state.languagePackage.welcomePage}

              />,
              contentString:"homePage",
              navigationOptions:"initialNavigationOptions"
          }
          )
  }

  componentWillMount(){
      let siteToken=localStorage.getItem("siteToken");
      let endpoint=localStorage.getItem('endpoint')
      if(siteToken&&endpoint){
          let language=this.state.language
        this.setState(
            {
                siteToken:siteToken,
                endpoint:endpoint,
                contentString:"homePage",
                content:<HomePage textContent={this.state.languagePackage.welcomePage}/>,
                options:authenticatedNavigationOptions,
                navigationOptions:"authenticatedNavigationOptions"
            }
            )
      }
  }

  refreshContentByString(language){
      switch(this.state.contentString){
          case 'homePage':
              this.setState({content:<HomePage textContent={languagePackage[language].welcomePage}/>})
              break;
          case 'registration':
              this.setState({content:<Registration textContent={languagePackage[language].registration}/>})
              break;
          case 'login':
              this.setState({content:<Login textContent={languagePackage[language].login}/>})
              break;
          case 'settings':
              this.setState({content:<Settings token={this.state.siteToken}/>})
      }
  }

  navigationEvent(operation){
    switch(operation){
        case 'registration':
            this.setState({
                contentString: "registration",
                content:<Registration textContent={this.state.languagePackage.registration}/>
            });
            break;
        case 'login':
            this.setState({
                contentString: "login",
                content:<Login textContent={this.state.languagePackage.login} authenticate={this.authenticate}/>});
            break;
        case 'homePage':
            let language=this.state.language
          this.setState({
              contentString: "homePage",
              content:<HomePage textContent={this.state.languagePackage.welcomePage}/>
          });
          break;
        case 'aboutUs':
          this.setState({contentString: "aboutUs",content:<AboutUs/>});
            break;
        case 'contactUs':
          this.setState({contentString: "contactUs",content:<ContactUs/>});
            break;
        case 'quotation':
          this.setState({contentString: "quotation",content:<Cotacoes sessionToken={this.state.siteToken}/>});
            break;
        case 'logout': {
            this.logout();
        }
            break;
        case 'language':
        {
            let language=this.state.language;
            let menuOptions=this.state.navigationOptions
            if(language==="portuguese"){
                language="english"
            }
            else{
                language="portuguese"
            }

            this.setState(
                {
                    language:language,
                    languagePackage:languagePackage[language],
                    options:languagePackage[language][menuOptions]
                })
            this.refreshContentByString(language)

        }
            break;
        case 'settings':
            this.setState({content: <Settings token={this.state.siteToken}/>});
            break;
        default:
            {
                let language=this.state.language;
                this.setState({content: <HomePage textContent={this.state.languagePackage.welcomePage}/>});
            }
        break;
    }
  }

render(){
      // if(this.state.siteToken&&this.state.endpoint&&!this.state.loggedOut){
      //     return(
      //         <AuthenticatedMainPage
      //             options={authenticatedNavigationOptions}
      //             logout={this.logout}
      //         />
      //     )
      // }

    return (
        <div className="App">
            <TopNavigation
                selectedComponent={"homePage"}
                navigationEvent={this.navigationEvent}
                options={this.state.options}/>
          <div className="webSiteContent">
              <div className="websiteContentIneer">
              {this.state.content}
              </div>
          </div>
            <img src={plane}/>
        </div>
    );
}
}

export default App;
