import React from 'react';
import './settings.css';
import Loading from "../miscelaneous/Loading";
import ClientData from "../client_data/ClientData";
const url="https://67cx6s16mb.execute-api.sa-east-1.amazonaws.com/dev/data"
class ChangeContact extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selected:"",
            isLoading:true,
            daa:null
        }
    }

    componentWillMount(){
        let options={}
        options.headers={}
        options.method="GET"
        options.headers.authorizationToken=this.props.token
        fetch(url,options).then(response=>{return response.json()}).then(response=>{
            console.log(response)
            this.setState({
                data:response,
                isLoading:false})
        })
    }


    render(){
        if(this.state.isLoading){
            return (
                <div  className="settings-area">
                    <h1>Estamos carregando seus dados</h1>
                    <h6>Aguarde</h6>
                    <div className="changingDataLoadingBox"><Loading/></div>

                </div>
            )
        }
        return(
            <ClientData token={this.props.token} data={this.state.data}/>
        )

    }

}

export default ChangeContact;