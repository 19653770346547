import React from 'react'
import './login.css'
import Modal from 'react-modal';
import Forgot from "./Forgot";
const loginUrl="https://qlnm6t9vt0.execute-api.sa-east-1.amazonaws.com/dev"

class Login extends React.Component{

    constructor(props){
        super(props);
        this.state={
            password:null,
            email:null,
            error:false,
            isLogging:false,
            passwordReset:false,
            modalIsOpen: false
        }
        this.login=this.login.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    handleChange(e){
        e.preventDefault();
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    showWarning(){
        if(this.state.error){
            return(
                <div className="authenticationError">
                    Dados incorretos. Não foi possível autenticar
                </div>
            )
        }
    }

    login(e){
        e.preventDefault();
        this.setState({isLogging:true})
        if(this.state.email&&this.state.password){
            fetch(loginUrl,
                {
                    method:"POST",
                    body:JSON.stringify(this.state)
                }).then(response=>{return response.json()}).then(
                    response=>{
                        if("body" in response){
                            if(response.body.sessionToken){
                                this.props.authenticate(response.body);
                                this.setState({isLogging:false})
                            }
                            else{
                                this.setState({error:true})
                            }
                        }
                        else{
                            this.setState({error:true})
                        }
                    }
            )
        }
    }

    render(){
        const customStyles = {

            overlay: {zIndex: 1000}
        };

        return (
            <div className="loginOutterBox">
                <div className="loginBox">
                    <div className="loginField">
                        <div className="loginLabel">{this.props.textContent.fields.email}</div>
                         <div className="loginInput"><input name="email" onChange={this.handleChange}/></div>
                    </div>
                    <br/>
                    <div className="loginField">
                        <div className="loginLabel">{this.props.textContent.fields.password}</div>
                        <div className="loginInput"><input name="password" type="password" onChange={this.handleChange}/></div>
                    </div>
                    <br/>
                    <div className="loginField">
                        <button onClick={this.login}>Login</button>
                    </div>
                    <br/>
                    <br/>
                    <span className="forgotPassword" onClick={(e)=>{
                        e.preventDefault();
                        this.setState({modalIsOpen:true})
                    }}>
                        Esqueci minha senha
                    </span>
                    {this.showWarning()}
                </div>

                <Modal
                    className="loginModal"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <Forgot close={this.closeModal}/>
                </Modal>
            </div>
        )
    }

}

export default Login;