import React from 'react'
import './homePage.css'

class HomePage extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    writeParagraphs(i){
        let p=this.props.textContent.sections[i].paragraphs.map((paragraph)=>{
            return <p>{paragraph}</p>
        })
        return p
    }

    render(){
        return (
            <div className="homePage">
                <h2>{this.props.textContent.sections[0].heading}</h2>
                {this.writeParagraphs(0)}
                <br/>
                <h4>{this.props.textContent.sections[1].heading}</h4>
                {this.writeParagraphs(1)}

            </div>
        )
    }

}

export default HomePage;