import React from 'react'
import './login.css'
import {IoIosAirplane} from "react-icons/io";
const url="https://qlnm6t9vt0.execute-api.sa-east-1.amazonaws.com/dev/forgot"
class Forgot extends React.Component{

    constructor(props){
        super(props);
        this.state={
            email:null,
            emailConfirmation:null,
            isEmailValid:false,
            isEmailConfirmationValid:false,
            isRequesting:false,
            status:"NOT_INITIATED"
        }
        this.handleChange=this.handleChange.bind(this);
        this.submitRequest=this.submitRequest.bind(this);
    }

    handleChange(e){
        e.preventDefault();
        let regex=/^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/
        let valid=regex.test(e.target.value);
        if(e.target.name==='email'){
            if(valid){
                this.setState({
                    email:e.target.value,
                    isEmailValid:true
                })
            }else{
                this.setState({
                    email:e.target.value,
                    isEmailValid:false
                })
            }
        }
        else{
            if(valid){
                this.setState({
                    emailConfirmation:e.target.value,
                    isEmailConfirmationValid:true
                })
            }else{
                this.setState({
                    emailConfirmation:e.target.value,
                    isEmailConfirmationValid:false
                })
            }
        }
    }

    submitRequest(e){
        e.preventDefault();
        let request={"email": this.state.email}
        this.setState({isRequesting:true})
        fetch(url,{
            method:"POST",
            body:JSON.stringify(request)
        }).then(response=>{return response.json()}).then(response=>{
            if(response.statusCode===200){
                if(response.body){
                    this.setState({
                        status:response.body,
                        isRequesting:false
                    })
                }
            }
            if(response.statusCode===400){
                this.setState({
                    status: "FAILED",
                    isRequesting:false
                })
            }
        })
    }

    showConfirmButton(){
        if((this.state.email===this.state.emailConfirmation)&&
            this.state.isEmailValid){
            return(
                <div className="loginField">
                    <button
                        onClick={this.submitRequest}>
                        Confirmar
                    </button>
                </div>
            )
        }
    }

    render(){
        if(this.state.isRequesting){
            return(
                <div className="forgotPasswordModal">
                    <div className="animation">
                        <IoIosAirplane className="littlePlane"/>
                    </div>
                    <div className="loginOutterBox">
                        <div className="loginBox">
                            <h4>Estamos processando seu pedido.</h4>
                            <p>1. Estamos preparando o link para alterar a senha.</p>
                            <p>2. Verifique o seu e-mail.</p>

                        </div>


                    </div>
                </div>
            )
        }
        if(this.state.status==="SUCCESS"){
            return (
                <div className="forgotPasswordModal">
                    <div
                        className="closeModal"
                        onClick={this.props.close}
                    >

                    </div>
                    <div className="loginOutterBox">
                        <div className="loginBox">
                            <h4>Pedido de alteração de senha realizado.</h4>
                            <br/>
                            <p>1. Enviamos um link para o teu e-mail.</p>
                            <p>2. Confirme a solicitação, clicando no link enviado.</p>
                            <p>3. Em seguida, você vai receber uma nova senha em seu e-mail.</p>

                        </div>


                    </div>
                </div>
            )
        }
        if(this.state.status==="FAILED"){
            return (
                <div className="forgotPasswordModal">
                    <div
                        className="closeModal"
                        onClick={this.props.close}
                    >

                    </div>
                    <div className="loginOutterBox">
                        <div className="loginBox">
                            <h4>Oops... não conseguimos solicitar nova senha.</h4>
                            <br/>
                            <p>1. Talvez você ainda não tenha um cadastro.</p>
                            <p>2. Talvez você já tenha solicitado o link.</p>
                            <p>3. O link enviado para o teu e-mail é válido por 20 minutos.</p>
                            <p>4. Solicite senha novamente, somente se o link tiver expirado.</p>

                        </div>


                    </div>
                </div>
            )
        }
        return (
            <div className="forgotPasswordModal">
                <div
                    className="closeModal"
                    onClick={this.props.close}
                >

                </div>
                <div className="loginOutterBox">
                    <div className="loginBox">
                        <h4>Solicitar nova senha</h4>
                        <p>1. Confirme teu e-mail.</p>
                        <p>2. Clique no link enviado para o teu e-mail.</p>
                        <p>3. Em seguida enviaremos-lhe uma nova senha.</p>
                        <p>4. Sugerimos alterar a senha através do portal do cliente.</p>
                        <div className="loginField">
                            <div className="loginLabel">E-mail</div>
                            <div className="loginInput"><input name="email" onChange={this.handleChange}/></div>
                        </div>
                        <br/>
                        <div className="loginField">
                            <div className="loginLabel">Repita o e-mail</div>
                            <div className="loginInput"><input name="emailConfirmation" onChange={this.handleChange}/></div>

                        </div>
                        <br/>
                        {this.showConfirmButton()}
                        <br/>
                        <br/>
                        <span className="forgotPassword" onClick={(e)=>{
                            e.preventDefault();
                            this.setState({modalIsOpen:true})
                        }}>

                    </span>

                    </div>


                </div>
            </div>
        )
    }

}

export default Forgot;