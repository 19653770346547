import React from 'react'

class AboutUs extends React.Component{

    constructor(props){
        super(props);
        this.state={}
    }

    render(){
        return (
            <div>
                This is the about us
            </div>
        )
    }

}

export default AboutUs;