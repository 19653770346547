import React from 'react';
import './topnavigation.css';
import logo from "../resources/logo.png";

class TopNavigation extends React.Component {
   constructor(props){
       super(props);
       this.state={
           selected:this.props.selectedComponent
       }
   }

   showMenuOptions(){
       if(this.props.options){
           let options=this.props.options.map((option,index)=>{
               return <span
                   key={index}
                   className={"selected_"+(this.state.selected===option.value).toString()}
                   onClick={
                       (e)=>{
                        e.preventDefault();
                        if(typeof(this.props.navigationEvent)==='function'){
                            this.props.navigationEvent(option.value)

                        }
                        this.setState({selected:option.value})
                       }}>
                   <div className={'navigationOutter'}>
                       <div className="navigationLabel">{option.label}</div>
                        <div className="navigationDescription">{option.description}</div>
                   </div>

               </span>
           })
           return <div>
               {options}

           </div>
       }
   }

   render(){
       return   (

           <div>
               <div className="mainPageTopNavigationOutter">
                   <div className="mainTopNavigationMiddle">
                       <div className="mainTopNavigationInner">
                           {this.showMenuOptions()}


                       </div>
                   </div>
               </div>
               <div className="topNavigationLogo"><img src={logo}/></div>

           </div>
           )


   }
}

export default TopNavigation;
