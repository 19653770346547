import React from 'react';
import './settings.css'
class ChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state={
            selected:"",
            password:null,
            passwordConfirmation:null
        }
        this.handleChange=this.handleChange.bind(this)
    }

    handleChange(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value})
    }

    showConfirmButton(){
        if((this.state.password&&this.state.passwordConfirmation)){
            if(this.state.password===this.state.passwordConfirmation){
                const pattern=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
                const isValid=(pattern.test(this.state.password));
                if(isValid){
                    return(
                        <button>
                            Alterar
                        </button>
                    )
                }
            }
        }
    }

    render(){
        return (
            <div  className="settings-area">
                <p className="settingsTitle">Altere a senha</p>
                <p>1. A senha deve ter pelo menos 8 dígitos.</p>
                <p>2. A senha deve possuir pelo menos 1 número.</p>
                <p>3. A senha deve conter pelo menos 1 letra maiúscula e uma minúscula.</p>
                <div className="newPasswordOutter">
                    <div className="newPasswordLabel">Nova senha:</div> <input onChange={this.handleChange} name="password" type="password"/>
                    <br/>
                    <div className="newPasswordLabel">Repita senha:</div><input onChange={this.handleChange}  name="passwordConfirmation" type="password"/>
                </div>
                {this.showConfirmButton()}
            </div>
        )
    }

}

export default ChangePassword;