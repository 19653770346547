import React from 'react';
import './clientData.css';

const ddds=function(){
    let data=[]
    for(let i=10;i<100;i++){
        if(i%10!==0){
            data.push(i)
        }
    }
    return data
}

const states=["AC","AL","AP","AM","BA","CE","DF","ES","GO","MA","MT","MS",
"MG","PA","PB","PR","PE","PI","RJ","RN","RS","RO","RR","SC","SP","SE","TO"]

class ClientDataEdit extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isEditingIdentification:false,
            hasDataChanged:false
        }
        this.haveAttributesChanged=this.haveAttributesChanged.bind(this)

    }

    getStateOptions(){
        const options=states.map((state)=>{
            return (
                <option value={state}>{state}</option>
            )
        })
        return(
            <select
                onChange={this.props.changeData}
                name="state">
                <option value="" selected disabled>--</option>
                {options}
                </select>
        )
    }

    getDDDoptions(){
        const options=ddds().map((ddd)=>{
            return (
                <option value={ddd}>{ddd}</option>
            )
        })
        return(
            <select
                onChange={this.props.changeData}
                name="whatsAppDDD">{options}</select>
        )
    }

    haveAttributesChanged(){
        const attributes=["name","consigned","id","stateRegistration",
            "whatsappNumber","whatsapDDD","address","suburb","city",
            "state","zipCode"]
        //check if things are different
        let hasChanged=false;
        for(let i=0;i<attributes.length;i++){
            let attribute=attributes[i];
            if(this.props.data[attribute]!==this.props.state[attribute]){
                this.setState({hasDataChanged:true});
                hasChanged=true;
                break;
            }
        }
        if(hasChanged){
            this.setState({hasDataChanged:true})
        }
    }

    render(){
        return(
            <div className="clientInfo">
                <tbody>
                <table>
                    <tr>
                        <th>Nome:</th>
                        <td>
                            <input
                                onChange={this.props.changeData}
                                name="name"
                                placeholder={this.props.data.name}
                            />
                            </td>
                    </tr>
                    <tr>
                        <th>Consignado:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="consigned"
                            placeholder={this.props.data.consigned}
                        /></td>
                    </tr>
                    <tr>
                        <th>CPF/CNPJ:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="id"
                            placeholder={this.props.data.id}
                        /></td>
                    </tr>
                    <tr>
                        <th>Inscrição Estadual:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="stateRegistration"
                            placeholder={this.props.data.stateRegistration}
                        /></td>
                    </tr>
                    <tr>
                        <th>E-mail:</th>
                        <td>{this.props.data.email}</td>
                    </tr>
                    <tr>
                        <th>Whatsapp:</th>
                        <td>
                            {this.getDDDoptions()}
                            <input
                                onChange={this.props.changeData}
                                type="number"
                                name="whatsappNumber"
                                placeholder="Número - entre somente valores numéricos"
                            />
                        </td>
                    </tr>
                </table>
                </tbody>
                <br/>
                <tbody>
                <table>
                    <tr>
                        <th>Endereço:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="address"
                            placeholder={this.props.data.address}
                        /></td>
                    </tr>
                    <tr>
                        <th>Bairro:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="suburb"
                            placeholder={this.props.data.suburb}
                        /></td>
                    </tr>
                    <tr>
                        <th>Município:</th>
                        <td><input
                            onChange={this.props.changeData}
                            name="city"
                            placeholder={this.props.data.city}
                        /></td>
                    </tr>
                    <tr>
                        <th>Estado:</th>
                        <td>{this.getStateOptions()}</td>
                    </tr>
                </table>
                </tbody>
            </div>
        )

    }

}

export default ClientDataEdit;