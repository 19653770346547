import React from 'react';
import './settings.css'
import SettingsArea from "./SettingsArea";
import ChangePassword from "./ChangePassword";
import ChangeAddress from "./ChangeAddress";
import ChangeContact from "./ChangeContact";

class Settings extends React.Component{
    constructor(props){
    super(props);
    this.state={
        selected:"default",
        components:{
            password:<ChangePassword token={this.props.token}/>,
            address:<ChangeAddress token={this.props.token}/>,
            contacts:<ChangeContact token={this.props.token}/>,
            default:''
        }
    }
    this.isSelected=this.isSelected.bind(this);
    }

    isSelected(item){
        if(this.state.selected===item){
            return 'selected'
        }
        return 'not-selected';
    }

    handleClick(item){
        this.setState({selected:item});
    }

    render(){
        return (
            <div>
                <div  className="settings">
                    {/*<p*/}
                        {/*onClick={(e)=>{*/}
                            {/*e.preventDefault();*/}
                            {/*this.handleClick('password')*/}
                        {/*}}*/}
                        {/*className={this.isSelected('password')}>*/}
                        {/*Senha*/}
                    {/*</p>*/}
                    <p onClick={(e)=>{
                        e.preventDefault();
                        this.handleClick('contacts')
                    }}
                        className={this.isSelected('contacts')}>
                        Meus dados
                    </p>
                </div>
                <SettingsArea component={this.state.components[this.state.selected]}/>

            </div>
        )
    }

}

export default Settings;