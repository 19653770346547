import React from 'react'
import './registration.css'
import { IoIosAirplane } from "react-icons/io";
import SecurityInformation from "../security_information/SecurityInformation";
const ddds=[]
const states=["AC","AL","AP","AM","BA","CE","DF","ES","GO","MA",
    "MT","MS","MG","PA","PB","PR", "PE","PI","RJ","RN","RS", "RO",
    "RR","SC","SP","SE","TO"]
const registrationUrl="https://qlnm6t9vt0.execute-api.sa-east-1.amazonaws.com/dev/register";
class Registration extends React.Component{

    constructor(props){
        super(props);
        this.state={
            name:null,
            email:null,
            state:null,
            city:null,
            zipCode:null,
            address:null,
            landlineNumber:null,
            suburb:null,
            id:null,
            consigned:null,
            bankPreference:null,
            stateRegistration:null,
            phone1:null,
            phone2:null,
            phone3:null,
            phone4:null,
            mobileNumber:null,
            poBox:null,
            isEmailValid:false,
            isSending:false,
            sent:false,
            success:true,
            showSecurityInfo:false
        }
        for(let i=11;i<100;i++){
            if(i%10!==0){
                ddds.push(i.toString())
            }
        }
        this.handleChange=this.handleChange.bind(this);
        this.submitRegistration=this.submitRegistration.bind(this);
        this.hideSecurityInformation=this.hideSecurityInformation.bind(this)


    }



    submitRegistration(e){
        e.preventDefault();
        this.setState({isChanging:true});
        let data={body:this.state};

        fetch(registrationUrl,
            {
                method:"POST",
                body:JSON.stringify(data)
            }).then(response=>{return response.json()}).then(
                res=>{
                console.log(res)
                if("body" in res){
                    if(res.body.includes("sucessfully registered")){
                        this.setState({isChanging:false,sent:true,success:true});
                    }
                }
            })
    }

    showButton(){
        if(this.state.isEmailValid){
            localStorage.setItem("registrationData",JSON.stringify(this.state))
            return(
                <div className="registerButton">
                    <button onClick={this.submitRegistration}>
                        Cadastrar
                    </button>
                </div>
            )
        }
    }

    hideSecurityInformation(e){
        e.preventDefault();
        this.setState({showSecurityInfo:false})
    }

    handleChange(e){
        e.preventDefault();

        if(e.target.name==="email"){
            let regex=/^(\D)+(\w)*((\.(\w)+)?)+@(\D)+(\w)*((\.(\D)+(\w)*)+)?(\.)[a-z]{2,}$/
            let valid=regex.test(e.target.value);
            this.setState({isEmailValid:valid,[e.target.name]:e.target.value})

        }
        else{
            this.setState({[e.target.name]:e.target.value})
        }
    }

    writeDisclaimer(){
        let p=this.props.textContent.disclaimer.map(paragraph=>{
            return <p>{paragraph}</p>
        })
        return p
    }

    render(){
        if(this.state.showSecurityInfo){
            return(
                <SecurityInformation
                    goBack={this.hideSecurityInformation}/>
            )
        }
        if(this.state.isSending){
            return(
                <div>
                <div className="animation">
                    <IoIosAirplane className="littlePlane"/>
                </div>
                    <div className="sending">
                        Enviando cadastro...
                    </div>
                </div>
            )
        }
        if(this.state.sent){
            if(this.state.success){
                return(
                    <div>
                        <p>Cadastro realizado com sucesso!</p>
                        <p>Em breve receberá uma confirmação via e-mail.</p>
                    </div>
                )
            }
        }
        let dddOptions=ddds.map((ddd,index)=>{
            return (
                <option value={ddd} key={index}>{ddd}</option>
            )
        })
        let stateOptions=states.map((state,index)=>{
            return (
                <option value={state} key={index}>{state}</option>
            )
        })
        return (
            <div>
                <h6>{this.props.textContent.heading}</h6>
                <div className="registrationInformation">
                    <p>{this.props.textContent.info}</p>
                </div>
                <div className="registrationItems">
                    <div className="registrationInputs">
                        <tbody>
                        <table>
                            <tr>
                                <td>{this.props.textContent.fields.companyName}:</td>
                                <td>
                                    <input
                                        name="name"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>{this.props.textContent.fields.consigned}:</td>
                                <td>
                                    <input
                                        name="consigned"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{this.props.textContent.fields.id}:</td>
                                <td>
                                    <input
                                        name="id"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>{this.props.textContent.fields.stateRegistration}:</td>
                                <td>
                                    <input
                                        name="stateRegistration"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{this.props.textContent.fields.telephone}:</td>
                                <td >
                                    <span>
                                        DDD:
                                        <select
                                            name="landLineDDD"
                                            onChange={this.handleChange}
                                        >
                                            <option selected disabled>{this.props.textContent.fields.areaCode}:</option>
                                            {dddOptions}
                                        </select>
                                    </span>
                                    <span className="landlineNumber">
                                        {this.props.textContent.fields.telephoneNumber}:
                                        <input
                                            name="landLineNumber"
                                            onChange={this.handleChange}
                                        />
                                    </span>
                                </td>
                                <td><span className="mandatory">*</span>{this.props.textContent.fields.email}:</td>
                                <td><input name="email" onChange={this.handleChange}/></td>
                            </tr>
                        </table>
                        </tbody>
                    </div>
                </div>
                <div className="registrationItems">
                    <div className="registrationInputs">
                        <tbody>
                        <table>
                            <tr>
                                <td>{this.props.textContent.fields.address}:</td>
                                <td>
                                    <input name="address"
                                           onChange={this.handleChange}
                                    />
                                </td>
                                <td>{this.props.textContent.fields.suburb}:</td>
                                <td>
                                    <input
                                        name="suburb"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{this.props.textContent.fields.city}:</td>
                                <td>
                                    <input
                                        name="city"
                                        onChange={this.handleChange}
                                    />
                                </td>
                                <td>{this.props.textContent.fields.state}:</td>
                                <td>
                                    <select
                                        name="state"
                                        onChange={this.handleChange}
                                    >
                                        {stateOptions}
                                    </select>
                                    <span className="label">{this.props.textContent.fields.zipCode}:</span>
                                    <input
                                        className={"cep"}
                                        name="zipCode"
                                        onChange={this.handleChange}
                                    />
                                </td>
                            </tr>

                        </table>
                        </tbody>
                    </div>
                </div>
                <div className="registrationInformation">
                    {this.writeDisclaimer()}
                    <p>Para saber mais sobre segurança, <span className="clickHere" onClick={(e)=>{
                        e.preventDefault();
                        this.setState({showSecurityInfo:true})
                    }}>clique aqui.</span></p>

                </div>
                <div>
                    {this.showButton()}
                </div>
            </div>
        )
    }

}

export default Registration;