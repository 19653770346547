import React from 'react';
import './clientData.css';
import { FiEdit } from "react-icons/fi";
import ClientDataEdit from "./ClientDataEdit";
import Loading from "../miscelaneous/Loading";
const url="https://67cx6s16mb.execute-api.sa-east-1.amazonaws.com/dev/data"
class ClientData extends React.Component{
    constructor(props){
        super(props);
        this.state={
            isEditingIdentification:false,
            name:this.props.name,
            email:this.props.email,
            consigned:this.props.consigned,
            id:this.props.id,
            stateRegistration:this.props.stateRegistration,
            whatsapp:this.props.whatsapp,
            whatsappNumber:this.props.whatsappNumber,
            whatsappDDD:this.props.whatsappDDD,
            address:this.props.address,
            suburb:this.props.suburb,
            city:this.props.city,
            state:this.props.state,
            zipCode:this.props.zipCode,
            hasDataChanged:false,
            isModalOpen:false,
            isChanging:false,
            changed:false,
            sessionToken:this.props.token
        }
        this.changeEditIdentification=this.changeEditIdentification.bind(this)
        this.handleAttributeChange=this.handleAttributeChange.bind(this)
        this.haveAttributesChanged=this.haveAttributesChanged.bind(this)
        this.updateClientInfo=this.updateClientInfo.bind(this)
    }

    updateClientInfo(e){
        e.preventDefault();
        this.setState({
            isChanging:true,
            changed:false,
            isEditingIdentification:false
        })
        let options={
            method: "PUT",
            mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(this.state)
        }
        fetch(url,options).then(response=>{return response.json()}).then(
            response=>{
                if((response.statusCode===200)&&(response.body==="SUCCESS")){
                    this.setState({
                        isChanging:false,
                        changed:response.body
                    })
                }else{
                    this.setState({
                        isChanging:false,
                        changed: "FAILED"
                    })
                }
            }
        )
    }

    showSendButton(){
        if(this.state.hasDataChanged){
            return(
                <div className="changeClientDataButton">
                    <button onClick={this.updateClientInfo}>Alterar</button>

                </div>
            )
        }
    }

    haveAttributesChanged(){
        const attributes=["name","consigned","id","stateRegistration",
            "whatsappNumber","whatsapDDD","address","suburb","city",
            "state","zipCode"]
        //check if things are different
        let hasChanged=false;
        for(let i=0;i<attributes.length;i++){
            let attribute=attributes[i];
            if(this.props.data[attribute]!==this.state[attribute]){
                this.setState({hasDataChanged:true});
                hasChanged=true;
                break;
            }
        }
        if(hasChanged){
            this.setState({hasDataChanged:true})
        }
    }

    handleAttributeChange(e){
        e.preventDefault();
        if(e.target.value!==""){
            this.setState({[e.target.name]:e.target.value});
        }
        else{
            this.setState({[e.target.name]:this.props.data[e.target.name]});
        }

        const attributes=["name","consigned","id","stateRegistration",
            "whatsappNumber","whatsapDDD","address","suburb","city",
            "state","zipCode"]
        //check if things are different
        let hasChanged=false;
        for(let i=0;i<attributes.length;i++){
            let attribute=attributes[i];
            if(this.props.data[attribute]!==this.state[attribute]){
                this.setState({hasDataChanged:true});
                hasChanged=true;
                break;
            }
        }
        if(hasChanged){
            this.setState({hasDataChanged:true})
        }
    }

    changeEditIdentification(e){
        e.preventDefault();
        this.setState({isEditingIdentification:!this.state.isEditingIdentification})
    }

    showClientIdentification(){
        if(this.state.isEditingIdentification){
            return <div>
                <ClientDataEdit
                    state={this.state}
                    changeData={this.handleAttributeChange}
                    data={this.props.data}/>
                {this.showSendButton()}

            </div>
        }
        else if(this.state.isChanging){
            return(
                <div>
                    <h4>Estamos atualizando seus dados</h4>
                    <h5>Por favor, aguarde.</h5>
                    <div className="changingDataLoadingBox"><Loading/></div>
                </div>
            )
        } else if((!this.state.isChanging)&&(this.state.changed==="SUCCESS")){
            return <div>Dados alterados.</div>
        } else if((!this.state.isChanging)&&(this.state.changed==="FAILED")){
            return <div>We could not update data</div>
        }
        else{
            return(
                <div className="clientInfo">

                    <tbody>
                    <table>
                        <tr className="editClientInfo">
                            <td colSpan="2">
                                <span
                                    onClick={this.changeEditIdentification}
                                >
                                    <FiEdit/> Editar
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <th>Nome:</th>
                            <td>{this.props.data.name}</td>
                        </tr>
                        <tr>
                            <th>Consignado:</th>
                            <td>{this.props.data.consigned}</td>
                        </tr>
                        <tr>
                            <th>CPF/CNPJ:</th>
                            <td>{this.props.data.id}</td>
                        </tr>
                        <tr>
                            <th>Inscrição Estadual:</th>
                            <td>{this.props.data.stateRegistration}</td>
                        </tr>
                        <tr>
                            <th>E-mail:</th>
                            <td>{this.props.data.email}</td>
                        </tr>
                        <tr>
                            <th>Whatsapp:</th>
                            <td>{this.props.data.whatsapp}</td>
                        </tr>
                    </table>
                    </tbody>
                    <br/>
                    <tbody>
                    <table>
                        <tr>
                            <th>Endereço:</th>
                            <td>{this.props.data.address}</td>
                        </tr>
                        <tr>
                            <th>Bairro:</th>
                            <td>{this.props.data.suburb}</td>
                        </tr>
                        <tr>
                            <th>Município:</th>
                            <td>{this.props.data.city}</td>
                        </tr>
                        <tr>
                            <th>Estado:</th>
                            <td>{this.props.data.state}</td>
                        </tr>
                        <tr>
                            <th>CEP:</th>
                            <td>{this.props.data.zipCode}</td>
                        </tr>
                    </table>
                    </tbody>
                </div>
            )
        }
    }

    render(){
      return this.showClientIdentification()

    }

}

export default ClientData;