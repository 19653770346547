import React from 'react'
import './quotation.css'
import {TiTick} from 'react-icons/ti'
import {FaSearch} from 'react-icons/fa'

import NewProductUserInput from "./NewProductUserInput";
import ListOfProducts from "./list_of_products/ListOfProducts";

const productsListUrl="https://b1l4ukgir4.execute-api.sa-east-1.amazonaws.com/prod/productslist"
const quotationURL="https://b1l4ukgir4.execute-api.sa-east-1.amazonaws.com/prod/quotation"

class Cotacoes extends React.Component{

    constructor(props){
        super(props);
        this.state={
            email:null,
            emailValidation:null,
            isEmailValid:false,
            products:[],
            options:[],
            selectedProduct:null,
            quantity:null,
            quotationList:[],
            selectPlaceholder:"Selecione um item para a cotação",
            isSending:false,
            requestId:null
        }
        this.handleChange=this.handleChange.bind(this);
        this.onChangeFunc=this.onChangeFunc.bind(this);
        this.addProduct=this.addProduct.bind(this);
        this.submitQuotationRequest=this.submitQuotationRequest.bind(this)
        this.removeItem=this.removeItem.bind(this);
        this.clearData=this.clearData.bind(this)
    }

    clearData(){
        localStorage.removeItem("quotationList");
        this.setState({
            email:null,
            emailValidation:null,
            isEmailValid:false,
            products:[],
            options:[],
            selectedProduct:null,
            quantity:null,
            quotationList:[],
            selectPlaceholder:"Selecione um item para a cotação"
        })
    }


    removeItem(index){
        let newList=[]
        this.state.quotationList.forEach(product=>{
            if(index!==product.index){
                let p=product
                p.index=newList.length+1;
                newList.push(p)
            }
        })
        this.setState({quotationList:newList})
        localStorage.setItem("quotationList",JSON.stringify(newList))
    }


    submitQuotationRequest(){
        this.setState({isChanging:true})
        let data={
            email:this.state.email,
            items:this.state.quotationList,
            name:this.state.name,
            requestedDate:new Date(),
            landlineNumber:this.state.landlineNumber,
            sessionToken:this.props.sessionToken
        }
        console.log('selected products: ',data)
        localStorage.setItem('submission',JSON.stringify(data))
        fetch(quotationURL,{
            method:"POST",
            body:JSON.stringify(data)
        }).then(response=>{return response.json()}).then(
            response=>{
                if(response.statusCode===200){
                    localStorage.removeItem("quotationList");
                    this.setState({
                        requestId:response.body.requestId,
                        isChanging:false,
                        email:null,
                        emailValidation:null,
                        isEmailValid:false,
                        products:[],
                        options:[],
                        selectedProduct:null,
                        quantity:null,
                        quotationList:[],
                        selectPlaceholder:"Selecione um item para a cotação",
                    })
                }

            }
        )
    }

    addProduct(productInformation){
        let product=this.state.selectedProduct;
        product.quantity=productInformation;
        if(product.type){
            if(product.type==='sheet'){
                product.quantity.quantity=1
            }
        }
        let products=JSON.parse(JSON.stringify(this.state.quotationList));
        product.index=products.length+1
        products.push(product)
        localStorage.setItem("quotationList",JSON.stringify(products));
        localStorage.setItem("selectedProduct",null)

        this.setState({quotationList:products,selectedProduct:null, selectPlaceholder:"Selecione um item para a cotação"})
    }

    onChangeFunc(optionSelected) {
        this.setState({selectedProduct:optionSelected.value})
    }

    componentWillMount(){
        this.getProducts();
        let email=localStorage.getItem("email");
        let emailValidation=localStorage.getItem("emailValidation")
        if(email===emailValidation){
            this.setState({
                email:email,
                emailValidation:email
            })
        }
        let products=JSON.parse((localStorage.getItem("quotationList")))
        console.log(products)
        if(products){
            if(products.length>0){
                this.setState({quotationList:products})
            }
        }

    }

    getProducts(){
        fetch(productsListUrl,{method: "GET"})
            .then(response=>{return response.json()})
            .then(data=>{
                let options=[]
                data.forEach(product=>{
                    let item={
                        value:product,
                        label:product.name
                    }
                    options.push(item)
                })
                console.log(options)
                this.setState({products:data,options:options})
            })
    }

    verifyEmail(email){
        const emailPattern=/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
        return emailPattern.test(this.state[email])
    }


    showEmailValid(email){
        if(this.verifyEmail(email)){
            return <span className="isEmailValid"><TiTick/></span>
        }
    }

    emailInputStatus(){
        if(this.state.email){
            if(this.state.emailValidation){
                let validEmail=this.verifyEmail("email")
                let secondEmailValidation=this.verifyEmail("emailValidation")
                if(validEmail&&secondEmailValidation){
                    if(this.state.email===this.state.emailValidation){

                        return <div className="emailMatch">O e-mail parece estar correto</div>
                    }
                    else{
                        return <div className="emailDoesNotMatch">Os emails não conferem</div>
                    }
                }
            }
        }
    }

    handleChange(e){
        e.preventDefault();
        localStorage.setItem([e.target.name],e.target.value)
        this.setState({[e.target.name]:e.target.value})
        this.verifyEmail()
    }

    showSubmitButton(){

            if((this.state.quotationList.length>0)){
                return(
                    <div className="addProductButton">
                        <a
                            href="#"
                            className="myButton"
                            onClick={this.submitQuotationRequest}
                        >
                            Enviar
                        </a>

                    </div>
                )
            }



    }

    render(){

        if(this.state.isSending){
            return(
                <div className='sendingQuotation'>
                    Enviando requisição. Aguarde . . .
                </div>
            )
        }
        if(this.state.requestId){
            return (
                <div className='quotationSentSuccessfully'>
                    <h3>Requisição enviada com sucesso.</h3>
                    <br/>
                    <p>Número da requisição: {this.state.requestId}</p>
                </div>
            )
        }

        return (
            <div className="quotation">

                <div className="searchProductBox">
                    <div className="searchBox">
                        <h5>Procure os items: </h5>
                        <div className="searchDropDownOutter">
                            <div className="searchIcon"><FaSearch/></div>
                            <div className="searchDropDownInner">
                                <NewProductUserInput
                                    product={this.state.selectedProduct}
                                    addProduct={this.addProduct}
                                    options={this.state.options}
                                    placeholder={this.state.selectPlaceholder}
                                    name="form-field-name"
                                    onChangeFunc={this.onChangeFunc}
                                />
                            </div>

                        </div>

                    </div>

                    <ListOfProducts
                        products={this.state.quotationList}
                        removeItem={this.removeItem}
                    />
                </div>
                {this.showSubmitButton()}
            </div>
        )
    }

}

export default Cotacoes;
