import React from 'react'
import './listOfProducts.css'
import {TiTick} from 'react-icons/ti'
import {FaShoppingCart, FaTrash} from 'react-icons/fa'

class ListOfProducts extends React.Component{

    constructor(props){
        super(props);
        this.state={

        }

    }



    render(){
        if(this.props.products.length<1){
            return(
                <div className={"nothingOnCartInfo"}>
                    Nenhum item adicionado.
                </div>
            )
        }
        console.log(this.props.products)
        let rows=this.props.products.map((product,index)=>{
            if(product){
                return (
                    <tr key={index}>
                        <th>{product.index}</th>
                        <th>{product.name}</th>
                        <td>{product.quantity.quantity}</td>
                        <td>{product.quantity.width}</td>
                        <td>{product.quantity.length}</td>
                        <td className="trash"
                            onDoubleClick={
                            (e)=>{
                                //e.preventDefault();
                                this.props.removeItem(product.index)
                            }
                        }>
                            <FaTrash/>
                        </td>
                    </tr>
                )
            }
        })
            return (
                <div className="newProductUserInput">
                <div className="quoteCart">
                    <div>
                    <FaShoppingCart/>
                        <table>
                            <tbody>
                            <tr>
                                <th>Item</th>
                                <th>Produto</th>
                                <th>Quantidade</th>
                                <th>Comprimento</th>
                                <th>Largura</th>
                                <td></td>
                            </tr>
                            {rows}
                            </tbody>
                        </table>
                        <p>Clique duplo na lixeira para remover.</p>
                    </div>
                </div>
                </div>
            )

    }

}

export default ListOfProducts;
