import React from 'react';
import './loading.css';
import { IoIosAirplane } from "react-icons/io";

class Loading extends React.Component{

    render(){
        return (
            <div className="loadingPlaneOutter">
                <IoIosAirplane className="loadingPlane"/>
            </div>
        )
    }

}

export default Loading;