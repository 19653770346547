import React from 'react'


class AuthenticatedSuccessWindow extends React.Component{

    constructor(props){
        super(props);
        this.state={

        }

    }


    render(){
        return (
            <div>
            <div>
                Você está autenticado. Agora você pode nos enviar cotações.
            </div>
            </div>
        )
    }

}

export default AuthenticatedSuccessWindow;