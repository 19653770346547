import React from 'react'
import './quotation.css'
import Select from "react-select";


class NewProductUserInput extends React.Component{

    constructor(props){
        super(props);
        const options=this.props.options;
        this.state={
            width:null,
            length:null,
            quantity:null,
            select:{
                value: this.props.options[0],
                options
            }
        }
        this.handleChange=this.handleChange.bind(this)
    }

    setValue = value => {
        this.setState(prevState => ({
            select: {
                ...prevState.select,
                value
            }
        }));
    };

    handleChange(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.value.replace(",",".")})
    }


    showSelectComponent(){
        let value=null;
        if(this.props.product){
            value=this.props.product.name
        }

        
        return (
            <Select
                options={this.props.options}
                placeholder={value}
                name="form-field-name"
                onChange={
                    this.props.onChangeFunc
                }
                value={value}

            />

        )
    }

    showAddButton(){
        if(this.props.product){
            let button=(
                <div className="addProductButton">
                    <a
                        href="#"
                        className="myButton"
                        onClick={(e)=>{
                            e.preventDefault();
                            this.props.addProduct(this.state);
                            this.setState({
                                width:null,
                                length:null,
                                quantity:null
                            })
                        }}
                    >
                        Adicionar
                    </a>

                </div>
            )
            if(this.props.product.type!=='sheet'){
                if(this.state.quantity){
                    if(!isNaN(this.state.quantity)){
                        return button
                    }
                }
            }
            else{
                if(this.state.width&&this.state.length){
                    if((!isNaN(this.state.width))&&(!isNaN(this.state.length))){
                        return button
                    }
                }
            }

        }

    }

    invalidInputWarning(){

    }

    showProductInputs(){
        let product=this.props.product;
        if(product){
            let label=product.name;
            let width=product.width;
            let length=product.length;
            let sheet=null;
            if(width&&length){
                sheet=(
                    <div>
                        <tbody>
                        <table>
                            <tr>
                                <td>
                                    Comprimento (centímetros):
                                </td>
                                <td>
                                    <input name="length" type="number" onChange={this.handleChange}/>
                                </td>
                            </tr>
                            <tr>
                                <td>Largura (centímetros):</td>
                                <td>
                                    <input name="width" type="number" onChange={this.handleChange}/>
                                </td>
                            </tr>
                        </table>
                        </tbody>
                    </div>
                )
            }
            let tube=null;
            if(label.toUpperCase().includes("TUBO")){
                tube=(
                    <div>
                        <tbody>
                        <table>
                            <tr>
                                <td>Insira a quantidade em metros: </td>
                                <td><input name="quantity" type="text" onChange={this.handleChange}/></td>
                            </tr>
                        </table>
                        </tbody>
                        <p>{this.invalidInputWarning()}</p>
                    </div>
                )
            }

            return(
                <div>
                    {sheet}{tube}
                    {this.showAddButton()}
                </div>
            )
        }

    }


    render(){

        return(
            <div>
                {this.showSelectComponent()}
                <div className="newProductUserInput">
                    {this.showProductInputs()}
                </div>
            </div>
        )
    }

}

export default NewProductUserInput;
